import React from "react";
import { useViewMode } from "shared/contexts/ViewMode";

import styles from "./Content006.module.scss";
import { GridView } from "./GridView";
import { MobileSliderView } from "./MobileSliderView";
import { DynamicSliderView } from "./SliderView";

export const Content006 = ({ payload }) => {
  const { isMobile } = useViewMode();
  const type = payload?.type ?? "grid";
  const title = payload?.title ?? "";
  const description = payload?.description ?? "";
  const items = payload?.items ?? [];
  const tabsEnabled = payload?.showTabs ?? false;

  const extractedTabs = [...new Set(items.map((item) => item.tag))];

  if (type === "grid") {
    return (
      <div className={styles.wrapper}>
        <GridView
          items={items}
          title={title}
          description={description}
          tabsEnabled={tabsEnabled}
          extractedTabs={extractedTabs}
        />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {isMobile ? (
        <MobileSliderView
          items={items}
          title={title}
          description={description}
          tabsEnabled={tabsEnabled}
          extractedTabs={extractedTabs}
        />
      ) : (
        <DynamicSliderView
          items={items}
          title={title}
          description={description}
          tabsEnabled={tabsEnabled}
          extractedTabs={extractedTabs}
        />
      )}
    </div>
  );
};

export default Content006;

import React from "react";
import {
  Container,
  LazyRenderingButton,
  Paragraph,
  Tabs,
} from "shared/components";
import { LazyRenderingProvider } from "shared/components/LazyRenderingButton";
import { TabsProvider } from "shared/components/Tabs/Tabs.Provider";

import { GridItem } from "../GridItem";
import styles from "./MobileSliderView.module.scss";

export const MobileSliderView = ({
  items,
  title,
  description,
  extractedTabs,
  tabsEnabled,
}) => {
  return (
    <Container className={styles.header}>
      <div className={styles.top}>
        <div className={styles["top-content"]}>
          <h2 className={styles.title}>{title}</h2>
          <Paragraph color="grey" className={styles.description}>
            {description}
          </Paragraph>
        </div>
      </div>
      <TabsProvider items={extractedTabs} defaultTab tabsEnabled={tabsEnabled}>
        <div className={styles["top-navigation"]}>
          <Tabs />
        </div>
        <LazyRenderingProvider
          size={items.length}
          initialLimit={3}
          incrementBy={3}
        >
          <div className={styles.wrapper}>
            <GridItem items={items} className={styles.item} />
          </div>
          <LazyRenderingButton appearance="light" />
        </LazyRenderingProvider>
      </TabsProvider>
    </Container>
  );
};

import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { Action, Icon, ImageLite, RenderIf } from "shared/components";
import { VIEW_MODES } from "shared/contexts/ViewMode";

import styles from "./Item.module.scss";

export const Item = ({ className, title, subTitle, description, media }) => {
  const [stateItem, setStateItem] = useState(false);

  const openItem = useCallback(
    () => (stateItem ? setStateItem(false) : setStateItem(true)),
    [stateItem]
  );

  return (
    <Action
      onClick={openItem}
      className={classNames(styles.wrapper, className)}
    >
      <ImageLite image={media} className={styles.logo} />
      <p className={styles.title}>{title}</p>
      <div className={styles.block}>
        <p className={styles.lead}>{subTitle}</p>
        <RenderIf viewMode={VIEW_MODES.MOBILE}>
          <Icon
            className={
              stateItem
                ? classNames(styles.icon, styles["icon-open"])
                : styles.icon
            }
            name="dropdown_arrow"
          />
          {stateItem && <p className={styles.text}>{description}</p>}
        </RenderIf>
      </div>
      <RenderIf viewMode={VIEW_MODES.DESKTOP}>
        <p className={styles.text}>{description}</p>
      </RenderIf>
    </Action>
  );
};

import { useEffect, useMemo } from "react";
import { useLazyRenderingState } from "shared/components/LazyRenderingButton";
import { useTabsState } from "shared/components/Tabs/Tabs.hooks";

import { Item } from "../Item";

export const GridItem = ({ items, className }) => {
  const { limit, resetState } = useLazyRenderingState();
  const { activeTab, isDefaultTab, tabsEnabled } = useTabsState();

  const filteredItems = useMemo(() => {
    if (isDefaultTab || !tabsEnabled) {
      return items;
    }

    return items.filter(
      ({ tag }) => tag.toLowerCase() === activeTab.toLowerCase()
    );
  }, [isDefaultTab, tabsEnabled, items, activeTab]);

  const slicedItems = useMemo(
    () => filteredItems.slice(0, limit),
    [filteredItems, limit]
  );

  useEffect(() => {
    if (tabsEnabled) {
      resetState();
    }
  }, [activeTab, resetState, tabsEnabled]);

  return slicedItems.map((item) => (
    <Item className={className} {...item} key={item.id} />
  ));
};

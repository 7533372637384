import classNames from "classnames";
import React from "react";
import { Container, Paragraph, Tabs } from "shared/components";
import { TabsProvider } from "shared/components/Tabs/Tabs.Provider";

import { GridItem } from "../GridItem";
import styles from "./GridView.module.scss";

export const GridView = ({
  items,
  title,
  description,
  extractedTabs,
  tabsEnabled,
}) => {
  return (
    <>
      <Container className={styles.header}>
        <h2 className={styles.title}>{title}</h2>
        <Paragraph>{description}</Paragraph>
      </Container>
      <TabsProvider items={extractedTabs} defaultTab tabsEnabled={tabsEnabled}>
        <Container className={styles["tabs-wrapper"]}>
          <Tabs />
        </Container>
        <div className={styles.blocks}>
          <div className={styles.wrapper}>
            <GridItem
              items={items}
              className={classNames(styles.item, {
                [styles.single]: items.length === 1,
              })}
            />
          </div>
        </div>
      </TabsProvider>
    </>
  );
};
